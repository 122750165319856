<template>
	<main>
		<div class="el-content">
			<el-tabs v-model="tab_name">
				<el-tab-pane label="小程序配置" name="wxapp"></el-tab-pane>
				<el-tab-pane label="模板消息" name="template"></el-tab-pane>
			</el-tabs>
			<components-wxapp v-if="tab_name === 'wxapp'"/>
			<components-template v-if="tab_name === 'template'"/>
		</div>
	</main>
</template>
<script>
import ComponentsWxapp from "./components/Wxapp.vue"
import ComponentsTemplate from "./components/Template.vue"

export default {
	components: {ComponentsWxapp, ComponentsTemplate},
	data() {
		return {
			tab_name: "wxapp",
		}
	},
}
</script>
