<template>
	<main>
		<div class="list">
			<div class="item" v-for="(item,index) in template" :key="index">
				<div class="title">
					<div class="tit">{{ item.title }}</div>
					<el-button class="btn" type="primary" size="mini" @click="create(item.type)">配置</el-button>
				</div>
				<div class="template" v-for="(v,i) in template_list" :key="i" v-if="Number(v.type) === item.type">
					<div class="title">模板id：{{ v.tpl }}</div>
				</div>
			</div>
		</div>
		<components-create ref="create" @success="getList"/>
	</main>
</template>

<script>
import componentsCreate from "./components/template/Create"
import {template} from "@/api/admin";

export default {
	name: 'index',
	data() {
		return {
			template: [
				{
					type: 1,
					title: "用户注册成功通知",
					field: [
						{key: "username", title: "姓名"},
						{key: "phone", title: "手机号码"},
						{key: "code", title: "工号"},
						{key: "department", title: "部门"},
						{key: "create_time", title: "注册时间"},
					]
				},
				{
					type: 2,
					title: "新工单通知",
					field: [
						{key: "sn", title: "单号"},
						{key: "type", title: "工单类型"},
						{key: "good_title", title: "设备名称"},
						{key: "good_code", title: "设备编号"},
						{key: "department", title: "部门"},
						{key: "create_time", title: "时间"},
					]
				},
				{
					type: 3,
					title: "工单完成通知",
					field: [
						{key: "sn", title: "单号"},
						{key: "type", title: "工单类型"},
						{key: "good_title", title: "设备名称"},
						{key: "good_code", title: "设备编号"},
						{key: "complete_time", title: "完成时间"},
					]
				},
				{
					type: 4,
					title: "报废审批通知",
					field: [
						{key: "good_title", title: "设备名称"},
						{key: "good_code", title: "设备编号"},
						{key: "department", title: "部门"},
						{key: "create_time", title: "时间"},
					]
				},
				{
					type: 5,
					title: "报废审批结果通知",
					field: [
						{key: "good_title", title: "设备名称"},
						{key: "good_code", title: "设备编号"},
						{key: "status", title: "状态"},
						{key: "create_time", title: "时间"},
					]
				},
			],
			template_list: []
		}
	},
	components: {componentsCreate},
	computed: {},
	watch: {},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		create(type) {
			var choose = this.template.find((item, index) => {
				return item.type === type;
			})
			this.$refs['create'].init(type, choose.field)
		},
		getList() {
			template.all().then((res) => {
				this.template_list = res.list;
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.list {padding: 0 15px;
	.item {padding: 15px 0;
		.title {display: flex;align-items: center;
			.btn {margin-left: 10px;}
		}
		.template {margin-top: 10px;color: #999;}
	}
}
</style>

