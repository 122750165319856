<template>
	<main>
		<el-dialog :close-on-click-modal="false" :title="title" :visible="visible" @close="close">
			<div class="content">
				<div class="step step1" v-if="step === 1">
					<div class="tip">请先在公众号管理后台添加模板后配置</div>
					<div class="content">
						<div class="table">
							<el-table border :data="template_list" v-loading="template_loading">
								<el-table-column prop="template_id" label="模板ID" ></el-table-column>
								<el-table-column prop="title" label="模版标题" ></el-table-column>
								<el-table-column prop="primary_industry" label="一级行业" ></el-table-column>
								<el-table-column prop="deputy_industry" label="二级行业" ></el-table-column>
								<el-table-column prop="content" label="模板内容" width="250" >
									<template slot-scope="scope">
										<div v-html="scope.row.content" style="white-space: pre-wrap;"></div>
									</template>
								</el-table-column>
								<el-table-column prop="action" label="操作">
									<template slot-scope="scope">
										<el-link type="primary" @click="choose(scope.row)">选用</el-link>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
				<div class="step step2" v-if="step === 2">
					<div class="flex">
						<div class="table">
							<div class="tip">选择通知所需的字段，并绑定相应的数据<br/>最多选择5个字段，最少选择2个字段</div>
							<div class="box">
								<el-table border height="400" :data="keyword_list" >
									<el-table-column prop="title" label="字段" ></el-table-column>
									<el-table-column prop="key" label="编码" ></el-table-column>
									<el-table-column prop="field" label="绑定通知数据">
										<template slot-scope="scope">
											<el-select v-model="scope.row.field" clearable >
												<el-option :value="item.key" :label="item.title" v-for="(item,index) in field_list" :key="index"></el-option>
											</el-select>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
					<div class="action">
						<div class="wrap">
							<el-button @click="close">取消</el-button>
							<el-button type="primary" @click="submit">确定</el-button>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {template} from "@/api/admin";
export default {
	name: 'index',
	data() {
		return {
			visible:false,
			step:1,
			template_list:[],
			template_loading:true,
			template:{},
			title:"选择消息模板",
			keyword_list:[],
			field_list:[]
		}
	},
	props:{

	},
	components:{},
	computed:{},
	watch:{},
	created() {},
	mounted() {},
	methods: {
		init(type,field = []){
			this.type = type;
			this.field_list = field;
			this.step = 1;
			this.visible = true;
			this.getTemplateList();
		},
		prev(){
			this.template_page = this.template_page - 1;
			this.getTemplateList();
		},
		next(){
			this.template_page = this.template_page + 1;
			this.getTemplateList();
		},
		submit(){
			for(var i =0;i<this.keyword_list.length;i++){
				if(!this.keyword_list[i].field){
					this.$message.error(`字段【${this.keyword_list[i].title}】未绑定通知数据`);
					return false;
				}
			}
			template.create({
				type:this.type,
				tpl:this.template.template_id,
				content:JSON.stringify(this.keyword_list)
			}).then((res)=>{
				this.$message.success('配置成功');
				this.close();
				this.$emit('success')
			})
		},
		close(){
			this.visible = false;
		},
		choose(row){
			this.template = row;
			this.step = 2;
			this.title = "绑定字段";
			this.createKeywordList();
		},
		createKeywordList(){
			var content = this.template.content;
			content = content.split('\n');
			var list = [];
			content.map((item,index)=>{
				if(item){
					var d = item.split('：');
					if(d && d.length > 1){
						list.push({
							title:d[0],
							key:d[1].replace('{{','').replace('}}',''),
						})
					}
				}
			})
			this.keyword_list = list;
		},
		getTemplateList(){
			this.template_loading = true;
			template.wechat().then((res)=>{
				this.template_list = res.list.filter((item,index)=>{
					return item.title !== '订阅模板消息'
				});
			}).finally(()=>{
				this.template_loading = false;
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.step1{padding-bottom: 30px;
	.tip{font-size:14px;color:#999;margin-bottom: 15px;line-height: 1.2;}
	.content{
		.page{display: flex;justify-content: right;padding: 15px;align-items: center;
			.wrap{display: flex;align-items: center;}
		}
	}
}
.step2{padding-bottom: 30px;
	.flex{display: flex;
		.tip{font-size:14px;color:#999;margin-bottom: 15px;line-height: 1.2;}
		.table{flex: 1;overflow: hidden;}
		.preview{width:300px;margin-left: 15px;
			.box{border: 1px solid #efefef;border-radius: 10px;display: flex;flex-direction: column;height:400px;
				.title{padding:15px;border-bottom: 1px solid #efefef;}
				.con{padding: 5px 15px;flex: 1;
					.li{padding: 10px 0;display: flex;align-items: center;justify-content: space-between;}
				}
				.link{border-top: 1px solid #efefef;padding: 15px;display: flex;align-items: center;justify-content: space-between;

				}
			}
		}
	}
	.action{display: flex;align-items: center;justify-content: right;margin: 30px 0 0;
		.wrap{display: flex;}
	}
}
</style>

