<template>
	<div class="form-box">
		<el-form ref="form" :model="form" label-width="140px">
			<div class="form-tab" >
				<el-form-item label="名称" prop="title">
					<el-input v-model="form.title"/>
				</el-form-item>
				<el-form-item label="logo" prop="logo">
					<form-upload accept="image" v-model="form.logo"/>
				</el-form-item>
				<el-form-item label="分享图" prop="share_image">
					<form-upload accept="image" v-model="form.share_image"/>
				</el-form-item>
				<el-form-item label="公众号二维码" prop="qrcode">
					<form-upload accept="image" v-model="form.qrcode"/>
				</el-form-item>
			</div>
		</el-form>
		<el-button type="primary" @click="submit">保存</el-button>
	</div>
</template>
<script>
import {config} from "@/api/admin";

export default {
	data(){
		return {
			form:{}
		}
	},
	mounted() {
		this.get();
	},
	methods:{
		get(){
			config.get().then((res)=>{
				this.form = res.detail || {};
			})
		},
		submit(){
			config.create(this.form).then((res)=>{
				this.$message.success('保存成功')
			})
		}
	}
}
</script>
